/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import texasHero from '../../images/locationsHeroes/hero-texas.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Apply for Aetna Medicare Plans in Texas | Call 833-998-1009 ',
          description:
            'Learn more about Aetna Medicare plans in Texas. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/texas',
        promoCode: '120785',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      ' Apply for Aetna Medicare Plans in Texas | Call 833-998-1009 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={texasHero}
                  alt="elderly man holds grandchild in a field"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare in Texas{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Insurance in Texas
              </Typography>
              <Typography variant="body">
                With all its rolling hills, wide open spaces, and warm
                temperatures, people from all over are heading to the Lone Star
                State for their retirement years. That may explain why the great
                state of Texas sits just behind Florida with the third highest
                number of people enrolled in Medicare. In fact, in 2018 Texas
                had over 4 million Medicare beneficiaries living within its
                borders.
              </Typography>
              <StaticImage
                src="../../images/illustrations/texas-stats.svg"
                alt="Over 3.6 million Texans enrolled in Medicare plans.*"
                layout="fixed"
                className="product-icons"
              />
              <Typography variant="legal">
                *Kaiser Family Foundation{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                  target="_blank"
                >
                  https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/
                </a>
              </Typography>
              <Typography variant="body">
                If you want to find out more about your Medicare options in
                Texas, you’re in the right spot. Speak with a friendly, licensed
                agent today for more information about Aetna Medicare plans in
                Texas.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-65.svg"
                alt="65 icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Types of Medicare Plans in Texas
              </Typography>
              <Typography variant="body">
                While coverage varies depending on where you live,
                Medicare-eligible folks, including those in Texas, have a wide
                range of health coverage options to choose from. You can choose
                a federally funded Medicare plan, such as Original Medicare, or
                you can select a Medicare Advantage plan sold through a
                Medicare-approved private insurance company.
              </Typography>
              <Typography variant="body">
                Speak with a licensed agent today to find out what kind of Texas
                Medicare plan you may be eligible for.
              </Typography>
              <Typography variant="h4" color="primary">
                Types of plans include:
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Original Medicare (Parts A and B) plans',
                      content: (
                        <>
                          <Typography>
                            Original Medicare is made up of two parts: Part A
                            and Part B. Medicare Part A, also known as “hospital
                            insurance,” covers your inpatient care at hospitals,
                            skilled nursing facilities, or hospices. Medicare
                            Part B, on the other hand, is sometimes called
                            “medical insurance” and helps pay for your
                            Medicare-approved outpatient care. This includes
                            medical services and supplies such as doctor’s
                            visits, physical therapy, and preventative care. It
                            also covers durable medical equipment (DME) that you
                            may need, such as oxygen pumps, walkers, and
                            wheelchairs. Original Medicare is funded and
                            administered by the federal government.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Advantage (Part C) plans',
                      content: (
                        <>
                          <Typography>
                            Medicare Advantage plans are regulated by the
                            federal government but are sold by private insurance
                            companies. By law, all Medicare Advantage plans must
                            provide all the same coverage as Original Medicare,
                            but most plans offer additional coverage, too. This
                            may include things like prescription drug coverage,
                            vision, dental, hearing aids, health and wellness
                            programs, or gym memberships.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Part D (prescription drug) plans',
                      content: (
                        <>
                          <Typography>
                            If you’re eligible for Medicare Parts A or B, you
                            can choose to purchase a Medicare Part D separately.
                            Medicare Part D helps pay for the cost of your
                            prescription drugs and medications. Coverage for
                            specific drugs varies based on what’s called a
                            Medicare “formulary,” a list of drugs separated into
                            “tiers” according to price. Note, Medicare Part D
                            coverage may be included in some Medicare Part C
                            (Medicare Advantage) plans. These plans are called
                            Medicare Advantage with Prescription Drug (MAPD)
                            plans.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart.svg"
                alt="heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I eligible for Medicare in Texas?
              </Typography>
              <Typography variant="body">
                If you’re 65 years or older, you’re eligible to receive Medicare
                benefits, no matter where you live in the country, though
                coverage varies according to where you live. Medicare benefits
                are also available to younger folks who suffer from certain
                medical conditions, such as end-stage renal disease (ESRD) or
                kidney failure and amyotrophic lateral sclerosis (also known as
                ALS or Lou Gehrig’s disease).
              </Typography>
              <Typography variant="body">
                In order to qualify for a Medicare Advantage plan, you must have
                both Medicare Part A and Part B (Original Medicare) and not have
                ESRD. To find out which Medicare plan you’re eligible for, talk
                to an agent licensed to enroll people in Medicare in the state
                of Texas.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">When can I enroll?</Typography>
              <Typography variant="body">
                If you’re enrolling in Medicare for the first time you qualify
                for both Part A and Part B of Medicare, you can enroll in a
                Medicare Advantage plan during your Initial Enrollment Period.
                Your Initial Enrollment Period begins three months before the
                month of your 65th birthday and ends three months after your
                birth month.
              </Typography>
              <Typography variant="body">
                If you’re already on Medicare and want to enroll in a Medicare
                Advantage plan, you can do so during the Annual Enrollment
                Period, which starts October 15 and ends December 7 every year.
              </Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in an Aetna
                Medicare Advantage plan during a Special Enrollment Period. For
                example, if you’ve recently moved or if you’re currently living
                in a nursing home. Talk to one of our licensed agents today to
                see if your life circumstance applies.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in Texas
              </Typography>
              <Typography variant="body">
                Navigating your coverage as a Medicare beneficiary can be really
                complex and confusing. The state of Texas offers a number of
                resources to help.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Texas Medicare Savings Programs',
                      content: (
                        <>
                          <Typography>
                            If you’re on Medicare and you’re struggling to pay
                            for your health care, Texas’s{' '}
                            <a
                              href="https://yourtexasbenefits.hhsc.texas.gov/programs/health/disability-or-65plus/medicare-savings-programs"
                              target="_blank"
                            >
                              Medicare Savings Programs
                            </a>{' '}
                            may help cut your costs by offering financial
                            assistance for expenses like deductibles,
                            copayments, and coinsurance. Assistance is based on
                            your income, so contact the Texas Department of
                            Health and Human Services to learn more.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title:
                        'Texas State Health Insurance Counseling and Assistance Program (SHIP)',
                      content: (
                        <>
                          <Typography>
                            Sometimes, you just need someone to sit down with
                            you and explain things in plain English.{' '}
                            <a
                              href="https://www.pparx.org/prescription_assistance_programs/texas_health_information_counseling_and_advocacy_program_hicap"
                              target="_blank"
                            >
                              Health Information Counseling &amp; Advocacy
                              Program of Texas
                            </a>{' '}
                            (HICAP) is the Texas branch of SHIP. HICAP’s mission
                            is to help provide senior or disabled citizens
                            statewide with free health insurance counseling, so
                            you can get all the information you need to make
                            wise decisions regarding your health care.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Texas Department of Insurance',
                      content: (
                        <>
                          <Typography>
                            The{' '}
                            <a
                              href="https://www.tdi.texas.gov/consumer/hicap/publicat.html"
                              target="_blank"
                            >
                              Texas Department of Insurance
                            </a>{' '}
                            publishes lots of helpful informational materials
                            for seniors and other Medicare recipients, including
                            information about long-term care and how to avoid
                            becoming the victim of insurance fraud.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title:
                        'Texas Department of Aging and Disability Services',
                      content: (
                        <>
                          <Typography>
                            First established in 2004, the Texas{' '}
                            <a
                              href="http://www.dads.state.tx.us/index.cfm"
                              target="_blank"
                            >
                              Department of Aging and Disability Services
                            </a>{' '}
                            (DADS) is the location of the Lone Star State’s
                            Aging and Disability Resource Center, which provides
                            a broad range of resources and services for Texas
                            Medicare beneficiaries, including personal
                            counseling.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
